import axios from 'axios';

// Secure API key (Use .env instead of hardcoding)
const azKey = 'w_Kxw06eY2mG5uinLCkFkgyV8HHuDqx8TX6QDl1LW6c';

// Azure Maps API URLs
const azureMapsRootURL = `https://atlas.microsoft.com/search/address/json?subscription-key=${azKey}&limit=6&api-version=1.0&countrySet=US&language=en-US&query=`;
const azureMapsReverseGeocodeRootURL = `https://atlas.microsoft.com/search/address/reverse/json?subscription-key=${azKey}&api-version=1.0&query=`;

// Function to get census tract from ArcGIS
export const getTractFromEsri = async (lat, long) => {
    try {
        const geo = { x: long, y: lat, spatialReference: { wkid: 4326 } };
        const fGeometry = encodeURIComponent(JSON.stringify(geo));

        const url = `https://services8.arcgis.com/d9Au2j3KckAl7VOw/arcgis/rest/services/California_Hard_to_Count_Index_2021/FeatureServer/0/query?geometry=${fGeometry}&geometryType=esriGeometryPoint&outFields=GEOID_1&f=pjson`;

        const response = await axios.get(url);
        return response.data.features?.length > 0 ? response.data.features[0].attributes.GEOID_1 : 'NO_RESULTS';
    } catch (error) {
        console.error("Error fetching tract:", error);
        return 'NO_RESULTS';
    }
};

// Function to geocode an address (Convert address to lat/lon)
export const geoCodeAddress = async (addressStr) => {
    try {
        console.log(`Geocoding: ${addressStr}`);
        const url = azureMapsRootURL + encodeURIComponent(addressStr);
        const response = await axios.get(url);

        if (!response.data.results || response.data.results.length === 0) return { success: false };

        const bestMatch = response.data.results[0];
        console.log(bestMatch);
        return {
            formattedAddress: bestMatch.address.freeformAddress,
            lat: bestMatch.position.lat,
            long: bestMatch.position.lon,
            zip: bestMatch.address.postalCode,
            city: bestMatch.address.municipality,
            state: bestMatch.address.countrySubdivision,
            success: bestMatch.score > 0.5 ? "SUCCESS" : "FAIL"
        };
    } catch (error) {
        console.error('Geocoder error:', error);
        return { success: "FAIL" };
    }
};

// Function to reverse geocode (Convert lat/lon to address)
export const reverseGeocodeAddress = async (lat, long) => {
    try {
        const url = `${azureMapsReverseGeocodeRootURL}${lat},${long}`;
        console.log("Reverse geocoding:", url);
        const response = await axios.get(url);
        return response.data;
    } catch (error) {
        console.error("Reverse geocode error:", error);
        return null;
    }
};
