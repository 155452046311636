import { geoCodeAddress, getTractFromEsri } from "./geoservices";
import QCT_TRACTS from "../data/qct_tracts.json";
import OVI_ZIPS from "../data/ovi_zips.json";

const getSociallyDisadvantaged = async (addressString) => {
    try {
        if (!addressString) {
            return { status: "error", message: "Invalid Request. Provide an address." };
        }

        // Normalize address to ensure California is included
        addressString = addressString.toLowerCase();
        if (!addressString.includes(",ca") && !addressString.includes(", ca") && !addressString.includes("ca")) {
            addressString += ", california";
        }

        // Geocode the address to get lat/lon and ZIP
        const geocodedAddr = await geoCodeAddress(addressString, {
            errorScore: 8.5,
            warnScore: 9.5
        });
        console.log(geocodedAddr);
        if (geocodedAddr.success === "FAIL") {
            return { status: "error", message: "Geocoding failed" };
        }

        console.log("Geocoded Address:", geocodedAddr);

        const { lat, long, zip } = geocodedAddr;

        // Check the ZIP-based OVI ranking first
        const ovi_rank5 = OVI_ZIPS[zip];

        // Get the census tract from ArcGIS
        const tract = await getTractFromEsri(lat, long);
        if (tract === "NO_RESULTS") {
            return { status: "error", message: "Unable to determine tract from address." };
        }

        // Check if the tract is a Qualified Census Tract (QCT)
        const isQCT = QCT_TRACTS[tract] || false;

        // Determine if the address is socially disadvantaged
        if (typeof zip !== "undefined" && typeof ovi_rank5 !== "undefined") {
            if (ovi_rank5 !== "UNKNOWN" && (ovi_rank5 === 4 || ovi_rank5 === 5)) {
                return {
                    status: "success",
                    tract,
                    isSDTract: true,
                    SDBasis: "ZIP OVI QUINTILE IS Q4 OR Q5",
                    ovi: ovi_rank5,
                    isOVI: true,
                    isQCT,
                    geocodedAddr
                };
            }
        }

        // If ZIP-based check fails, check the census tract (QCT)
        if (isQCT) {
            return {
                status: "success",
                tract,
                SDBasis: "TRACT IS QCT",
                isSDTract: true,
                isQCT,
                isOVI: false,
                geocodedAddr,
                ovi: ovi_rank5
            };
        }

        // If neither ZIP nor QCT criteria are met, return as NOT socially disadvantaged
        return {
            status: "success",
            tract,
            isSDTract: false,
            geocodedAddr,
            isQCT,
            isOVI: false,
            ovi: ovi_rank5
        };
    } catch (e) {
        console.error("Error in getSociallyDisadvantaged:", e);
        return { status: "error", message: e.message };
    }
};

export default getSociallyDisadvantaged;
