import React from 'react';
import loadImg from '../img/loading.gif';
import {default as  countyAMIRates}  from '../partials/ami.json';
import {default as zip2County} from '../partials/zip2county.json'
// import GoogleMap from "./googlemap"

class SDResults extends React.Component {


    getCounty(x){
        const zipCounty = zip2County[x] 
        if (zipCounty === undefined) return "ERROR: County not found";
        else
            return zipCounty;
    }

     roundToNearest50 = (x) => {
        const
            num_digits = x.toString().length,
            x_floor_50 = +x.toPrecision(num_digits - 2),
            x_ceil_50 = Number(x_floor_50) + 50,
            x_dist_to_floor_50 = x - x_floor_50,
            x_dist_to_ceil_50 = x_ceil_50 - x,
            x_to_nearest50 = (x_dist_to_floor_50 < x_dist_to_ceil_50) ? x_floor_50 : x_ceil_50;
        return x_to_nearest50;
     }

    getAmi(county, peopleInHousehold){
        const countyAMI = countyAMIRates.countyAMIRates2024[county] 
    
        if (countyAMI === undefined) return "ERROR: County not found";
        if (Number(peopleInHousehold) <= 8) return countyAMI[`hh_${peopleInHousehold}`]; // for peopleInHousehold 8 or below, simple lookup.
    
        // for peopleInHousehold > 8, there is a specific formula:
    
        // Eligibility Determination:
        // Determination of Income Limit for Households Larger than Eight Persons:
    
        // (1) multiply 4-person income limit by eight percent (8%),
        const stepOne = countyAMI.hh_4 * 0.08;
        // (2) multiply result by number of persons in excess of eight,
        const stepTwo = stepOne * (peopleInHousehold - 8);
        // (3) add the amount to the 8-person income limit, and 
        const stepThree = stepTwo + countyAMI.hh_8;
        // (4) round to the nearest $50.
        const stepFour = this.roundToNearest50(stepThree);
        return stepFour;
    
        // else return roundToNearest50(((countyAMI.hh_4 * 0.08) * (peopleInHousehold - 8)) + countyAMI.hh_8);
    
        // var hh_size_4 = countyAMIInfo[`hh_4`];
        // var hh_size_8 = countyAMIInfo[`hh_8`];
        // var hh_size_number_above_8 = hh_size - 8;
        // var ami_for_hh_size_unrounded = ((hh_size_4 * 0.08) * hh_size_number_above_8) + hh_size_8;
        // resp.ami_for_hh_size = roundToNearest50(ami_for_hh_size_unrounded);
    
    };
  
    componentDidMount() {
        // console.log(`SDResults - MOUNT`);
    }

    componentDidUpdate() {
        // console.log(`SDResults - componentDidUpdate`);

    }
    render() {
        // console.log('rendering SDResults');
        if (this.props.status === "INIT") {
            return null
        }
        if (this.props.status === "LOADING") {
            return (
                <div><img src={loadImg} alt="Be patient..." /></div>
            )
        }
        // console.log('--- this.props.SDResultsObj ---');
        // console.log(this.props.SDResultsObj);
        // console.log('--- this.props.SDResultsObj ---');
        if(this.props.status === "COMPLETE1"){
            if (!this.props.SDResultsObj.isSDTract) {
                return (
                    <div>
                        <hr />
                        <h2 className="ui header">Socially Disadvantaged Results</h2>
                        <h4>This is not a Socially Disadvantaged address.</h4>
                        <table>
                    <tbody>
                    <tr>
                            <td>Socially Disadvantaged Community</td>
                            <td>In Socially Disadvantaged OVI?</td>
                            <td>In Socially Disadvantaged QCT?</td>
                            <td>QCT #: </td>
                            <td>Zip Code: </td>
                            <td>AMI Percentage</td>
                    </tr>
                    <tr>
                    <td>{String("false")}</td>
                    <td>{String("false")}</td>
                    <td>{String(this.props.SDResultsObj.isQCT)}</td>
                    <td>{this.props.SDResultsObj.tract}</td>
                    <td>{this.props.SDResultsObj.geocodedAddr.zip}</td>
                    <td>{((this.props.SDResultsObj.HHIncome / this.getAmi(String(this.getCounty(String(this.props.SDResultsObj.geocodedAddr.zip))),parseInt(this.props.SDResultsObj.HHSize)) )* 100).toFixed(2)}</td>
                    </tr>
                    </tbody>
                    </table>
                    <br></br>
                    <div
                    style={{
                    display: 'flex',
                    alignItems: 'center',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    }}
                    >
                    <iframe title="google map" width="550" height="450" id="gmap_canvas" src={"https://maps.google.com/maps?q=" + this.props.SDResultsObj.geocodedAddr.formattedAddress + "&t=&z=18&ie=UTF8&iwloc=&output=embed"} frameBorder="0" scrolling="no" marginHeight="0" marginWidth="0"></iframe>
                    </div>
                    </div>
                )
            }
            return (
                <div>
                    <hr />
                    <h2 className="ui header">Socially Disadvantaged Results</h2>
                    <h4>This is a Socially Disadvantaged address.</h4>
                    <table>
                    <tbody>
                    <tr>
                            <td>Socially Disadvantaged Community</td>
                            <td>In Socially Disadvantaged OVI?</td>
                            <td>In Socially Disadvantaged QCT?</td>
                            <td>QCT #: </td>
                            <td>Zip Code: </td>
                            <td>AMI Percentage</td>
                    </tr>
                    <tr>
                    <td>{String("true")}</td>
                    <td>{String(this.props.SDResultsObj.isOVI)}</td>
                    <td>{String(this.props.SDResultsObj.isQCT)}</td>
                    <td>{this.props.SDResultsObj.tract}</td>
                    <td>{this.props.SDResultsObj.geocodedAddr.zip}</td>
                    <td>{((this.props.SDResultsObj.HHIncome / this.getAmi(String(this.getCounty(String(this.props.SDResultsObj.geocodedAddr.zip))),parseInt(this.props.SDResultsObj.HHSize)) )* 100).toFixed(2)}</td>
                    </tr>
                    </tbody>
                    </table>
                    <br></br>
                    <div
                    style={{
                    display: 'flex',
                    alignItems: 'center',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    }}
                    >
                        <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column', justifyContent: 'center' }}>
                        <iframe title="google map" width="550" height="450" id="gmap_canvas" src={"https://maps.google.com/maps?q=" + this.props.SDResultsObj.geocodedAddr.formattedAddress + "&t=&z=18&ie=UTF8&iwloc=&output=embed"} frameBorder="0" scrolling="no" marginHeight="0" marginWidth="0"></iframe>
                        {/* <GoogleMap addresses={this.props.SDResultsObj.geocodedAddr.zip} /> */}
                        </div>
                    </div>
                </div>

            )
        }
        else if (this.props.status === "COMPLETE2"){
                return (
                <div>
                    <hr />
                    <h2 className="ui header">Socially Disadvantaged Bulk Results</h2>
                    <table>
                    <tbody>
                        <tr>
                            <td>Address</td>
                            <td>Household Size</td>
                            <td>Household Income</td>
                            <td>Socially Disadvantaged Community</td>
                            <td>In Socially Disadvantaged OVI?</td>
                            <td>In Socially Disadvantaged QCT?</td>
                            <td>QCT #: </td>
                            <td>Zip Code: </td>
                            <td>AMI Percentage</td>
                        </tr>      
                        {this.props.SDResultsObjList.map((d) =>(
                            <tr key={d.geocodedAddr.formattedAddress}>
                                <td>{d.geocodedAddr.formattedAddress}</td>
                                <td>{d.HHSize}</td>
                                <td>{d.HHIncome}</td>
                                <td>{String(d.isSDTract)}</td>
                                <td>{String(d.isOVI)}</td>
                                <td>{String(d.isQCT)}</td>
                                <td>{d.tract}</td>
                                <td>{d.geocodedAddr.zip}</td>
                                <td>{((d.HHIncome / this.getAmi(String(this.getCounty(String(d.geocodedAddr.zip))),parseInt(d.HHSize)) )* 100).toFixed(2)}</td>
                            </tr>  
                        ))}
                    </tbody>
                    </table>
                    <br></br>
                    <div
                    style={{
                    display: 'flex',
                    alignItems: 'center',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    }}
                    >
                        {/* <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column', justifyContent: 'center' }}>
                        <GoogleMap addresses={this.props.SDResultsObjList.map((d) => d.geocodedAddr)} />
                        </div> */}
                    </div>
                </div>
                )
        }
    }
}

export default SDResults;
